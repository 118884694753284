.register-form-2 {
    border-radius: 5px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
    padding: 15px;
    margin: 140px auto 0;
    background: #f4f4f4;
}

.register-form-2 h3 {
    text-align: center;
    color: rgb(223, 0, 0);
    font-size: 35px;
}

.register-container form {
    padding: 5% 10% 10% 10%;
}

.btnSubmitRegister {
    width: 30%;
    border-radius: 1rem;
    padding: 1.5%;
    border: none;
    cursor: pointer;
}

.btnSubmit {
    background-color: rgb(165, 23, 22);
    color: #fff;
}

.register-form-2 .ForgetPwd {
    color: #fff;
    font-weight: 600;
    text-decoration: none;
}

.register-form-1 .ForgetPwd {
    color: rgb(165, 23, 22);
    font-weight: 600;
    text-decoration: none;
}

.form-group {
    margin-bottom: 1rem;
    text-align: center;
}

.form-group-input {
    margin-bottom: 1rem;
}

.item-aling {
    text-align: end;
}

.btn-primary {
    border-color: #a51716;
    background-color: #a51716;
    color: #FFFFFF;
    text-align: right;
}

.btn-primary:hover {
    border-color: #a51716;
    background-color: #ffffff;
    color: #a51716;
}