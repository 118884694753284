.title {
    text-align: center;
    color: #4b4b4b;
    font-size: 35px;
}

.form-group-input {
    margin-bottom: 1rem;
}

.btnSubmitRegister {
    border-radius: 1rem;
    padding: 1.5%;
    border: none;
    cursor: pointer;
}

.btnSubmit {
    background-color: rgb(165, 23, 22);
    color: #fff;
}

.modal-body-delete {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
}

.modal-delete {
    max-height: 275px;
}

.btn-ok {
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.modal-content {
    border: none;
}

.modal-footer {
    border-top: none;
}

.modal-user {
    max-height: 430px;
}

.modal-store {
    max-height: 500px;
}