.table-container {
    background-color: white;
    padding: 15px;
}

.ant-table {
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.ant-table-thead tr {
    background: #fab9b9;
}

.table-stock {
    padding: 40px;
    background-color: rgb(244, 246, 249);
    height: 100%;
    min-height: 100vh;
}

.form-stock {
    margin: 12px 15px 0px;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 9px 26px 0 rgb(0 0 0 / 19%);
    text-align: center;
}

.control-label-stock {
    color: rgb(223, 0, 0);
    margin: 0px 14px;
}

.btnSubmitFilter {
    width: 10%;
    border-radius: 1rem;
    padding: 0.7%;
    border: none;
    cursor: pointer;
    margin: 15px 0px 15px 182px;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 9px 26px 0 rgb(0 0 0 / 19%);
}

.input-filter {
    width: 80%;
    outline: 0;
    border-radius: 10px;
    border: 1px solid grey;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 9px 26px 0 rgb(0 0 0 / 19%);
}

.position {
    float: none !important;
}

.MuiTablePagination-selectLabel {
    margin-bottom: 0em;
}

.MuiTablePagination-displayedRows {
    margin-bottom: 0em;
}

.MuiDataGrid-columnHeader {
    background-color: rgb(244, 246, 249);
}

.table-style-stock {
    height: 667px;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 9px 26px 0 rgb(0 0 0 / 19%);
}

.struct-filter {
    padding: 17px 13px 20px 0px;
}

.title-input {
    display: block;
}

.filter-col {
    margin-bottom: 20px;
}


/*
Estilos para proyecto webapp Mediamarkt
*/


/***********************COLOR THEME***********************/


/*
	color: #a51716; ROJO MEDIAMARKT
	color: #4B4B4B; GRIS OSCURO
	color: #CECECE; GRIS CLARO
*/

#filters-zone {
    color: #4B4B4B !important;
    padding: 0 15px;
}

#filters-zone .container-fluid {
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
}


/*---*/

#filters-zone .accordion-body {
    padding: 0;
}

#filters-zone .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
    font-size: 1.35rem;
    letter-spacing: -0.5px;
    color: #4b4b4b;
    text-align: left;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

#filters-zone .accordion-button:not(.collapsed) {
    color: #4b4b4b;
    background-color: transparent;
    box-shadow: none;
}

#filters-zone .accordion-button::after {
    flex-shrink: 0;
    width: 1.50rem;
    height: 1.50rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.50rem;
    transition: transform .2s ease-in-out;
}

#filters-zone .accordion-button:not(.collapsed)::after {
    fill: #a51716;
    transform: rotate(-180deg);
}


/*---*/

#filters-zone .btn-primary {
    border-color: #a51716;
    background-color: #a51716;
    color: #FFFFFF;
    text-align: right;
}

#filters-zone .btn-primary:hover {
    border-color: #a51716;
    background-color: #ffffff;
    color: #a51716;
}

.shop-sel {
    border-color: #a51716;
}

.form-label {
    margin-bottom: .1rem;
}

.ant-collapse {
    background-color: transparent !important;
    border: none !important;
    border-bottom: none !important;
    border-radius: none !important;
    font-size: 1.2rem !important;
    letter-spacing: -0.5px;
}

.ant-collapse-item {
    border-bottom: none !important;
}

.ant-collapse-arrow {
    vertical-align: 5px !important;
}

.section-name {
    display: inline;
    margin-left: 10px;
    letter-spacing: -1px;
}

.margin-top {
    margin-top: 15px;
}