.login-form-1 {
    border-radius: 5px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
    padding: 15px;
    margin: 140px auto 0;
    background: #f5f6f8;
}

.login-title {
    color: #333;
    font-size: 30px;
    text-align: center;
    line-height: 40px;
    margin-bottom: 20px;
    letter-spacing: -1px;
}

.login-logo {
    margin: 0 auto 15px;
    display: block;
    width: 80%;
}

.control-label {
    margin-bottom: 0;
    color: rgb(223, 0, 0);
}

.control-input {
    border-radius: 0;
    border: 1px solid rgb(223, 0, 0);
}

.login-container form {
    padding: 10%;
}

.btnSubmit {
    width: 100%;
    border-radius: 5px;
    padding: 1.5%;
    border: none;
    cursor: pointer;
}

.login-form-1 .btnSubmit {
    color: #fff;
    background-color: rgb(165, 23, 22);
}

.form-group {
    margin-bottom: 1rem;
    text-align: center;
}

.form-group-input {
    margin-bottom: 1rem;
}