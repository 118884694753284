.menu-form {
    border-radius: 5px;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
    padding: 15px;
    margin: 140px auto 0;
    background: #f5f6f8;
}

@media (min-width: 768px) {
    .col-md-4 {
        width: 700px;
    }    
}

.menu-title {
    color: #333;
    font-size: 30px;
    text-align: center;
    line-height: 40px;
    margin-bottom: 20px;
    letter-spacing: -1px;
}

.menu-logo {
    margin: 0 auto 15px;
    display: block;
}

.btnMenu {
    margin-bottom: 1rem;
    text-align: center;
}

.item-aling {
    text-align: end;
}