.modal {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-height: 620px;
    max-width: 500px;
    outline: none;
    padding: 10px;
}

.modal-import {
    max-height: 220px !important;
}

.modal-fondo {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
}

.paddig-tp {
    padding-top: 5px;
    padding-bottom: 20px;
}

.title {
    color: rgb(165, 23, 22);
    font-size: 30px;
    text-align: center;
    line-height: 40px;
    margin-bottom: 20px;
    letter-spacing: -1px;
}

.btnColor {
    width: 100%;
    border-radius: 5px;
    padding: 1.5%;
    border: none;
    cursor: pointer;
    color: #fff;
    background-color: rgb(165, 23, 22);
}

.btnAdd {
    margin-left: 40px;
}

.btn-padding {
    padding: 0px 6px 4px;
}