.form {
    background-color: white;
}

.ant-table {
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.ant-table-thead tr {
    background: #fab9b9;
}

.table-stock {
    padding: 40px;
    background-color: rgb(244, 246, 249);
    height: 100%;
    min-height: 100vh;
}

.form-stock {
    margin: 40px 40px 0px;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 9px 26px 0 rgb(0 0 0 / 19%);
    text-align: center;
}

.control-label-stock {
    color: rgb(223, 0, 0);
    margin: 0px 14px;
}

.btnSubmitFilter {
    width: 10%;
    border-radius: 1rem;
    padding: 0.7%;
    border: none;
    cursor: pointer;
    margin: 15px 0px 15px 182px;
}

.input-filter {
    width: 20%;
}

.item-aling {
    text-align: end;
}

.position {
    float: none !important;
}

.table-style-movement {
    margin: 50px 0px 0px 15px;
    height: 632px;
    width: 98.5%;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 9px 26px 0 rgb(0 0 0 / 19%);
}