.btnColor {
    width: 100%;
    border-radius: 5px;
    padding: 1.5%;
    border: none;
    cursor: pointer;
    color: #fff;
    background-color: rgb(165, 23, 22);
}

.btnAdd {
    margin-left: 40px;
}

.btn-padding {
    padding: 0px 6px 4px;
}

.btn-logout {
    margin: 20px;
    color: rgb(165, 23, 22);
    font-size: 1.2rem;
    font-weight: 700;
}

.btn-color {
    background-color: rgb(165, 23, 22);
    color: white;
}

.btn-back {
    margin-top: -20px;
    background-color: rgb(165, 23, 22);
    padding: 0;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
    display: inline-block;
    line-height: 42px;
    width: 42px;
    height: 42px;
    text-align: center;
}

.btn-back-icon {
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 100%;
}

.navbar-brand {
    margin-left: 1rem;
}

.btn-back-table {
    margin-top: 13px;
    float: right;
    margin-right: 41px;
    background-color: rgb(165, 23, 22);
    color: white;
    padding: 0;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.spinner-position {
    position: fixed;
    left: 50%;
    bottom: 0;
    right: 0;
    top: 50%;
    color: rgb(165, 23, 22);
    width: 4rem;
    height: 4rem;
}

.btn-reload {
    margin-bottom: 15px;
}

.anticon {
    font-size: 20px !important;
}

.btn-margin-right {
    margin-right: 5px;
}

.btn-margin-left {
    margin-left: 5px;
}

.btn-movement {
    color: white;
    background-color: rgb(163, 23, 22);
    border-color: rgb(165, 23, 22);

}
.btn-movement:hover {
    color: white;
    background-color: rgb(245, 50, 50);
    border-color: rgba(245, 50, 50);

}

.btn_link {
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    width: 70px;
    font-size:  0.875rem;
}