.table-container {
    background-color: white;
    padding: 15px;
}

.ant-table {
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}

.ant-table-thead tr {
    background: #fab9b9;
}

.table-user {
    padding: 40px;
    height: 100%;
    min-height: 100vh;
}

.struct {
    padding: 65px;
}

.item-aling {
    text-align: end;
}

.MuiTablePagination-selectLabel {
    margin-bottom: 0em;
}

.MuiTablePagination-displayedRows {
    margin-bottom: 0em;
}

.MuiDataGrid-columnHeader {
    background-color: rgb(244, 246, 249);
}

.table-style-user {
    height: 632px;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 20%), 0 9px 26px 0 rgb(0 0 0 / 19%);
}